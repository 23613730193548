<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light" style="background-color: #F7E6FF">
    <router-link to="/" class="logo-link">
      <img :src="require('@/assets/logo.png')" alt="logo" class="logo">
    </router-link>
    <div class="navbar-carousel">
      <transition-group name="carousel" tag="div" class="carousel-wrapper">
        <p v-for="(message, index) in filteredMessages" :key="index" class="carousel-message">{{ message }}</p>
      </transition-group>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item" v-if="!isLoggedIn">
          <button @click="showLoginModal = true" class="btn btn-primary nav-button">Zaloguj</button>
        </li>
        <li class="nav-item" v-if="!isLoggedIn">
          <button @click="showRegisterModal = true" class="btn btn-secondary nav-button">Zarejestruj</button>
        </li>
        <li class="nav-item" v-if="isLoggedIn">
          <router-link to="/reservations" class="btn btn-primary nav-button">Moje rezerwacje</router-link>
        </li>
        <li class="nav-item" v-if="isLoggedIn">
          <button @click="logout" class="btn btn-secondary nav-button">Wyloguj</button>
        </li>
      </ul>
    </div>

    <!-- Login Modal -->
    <div v-if="showLoginModal" class="modal-overlay" @click.self="closeLoginModal">
      <div class="modal-content">
        <h3>Zaloguj</h3>
        <form @submit.prevent="login">
          <div class="form-group">
            <label for="emailOrPhone">E-mail lub numer telefonu</label>
            <input type="text" id="emailOrPhone" v-model="emailOrPhone" class="form-control" required>
          </div>
          <div class="form-group">
            <label for="password">Hasło</label>
            <input type="password" id="password" v-model="password" class="form-control" required>
          </div>
          <button type="submit" class="btn btn-primary btn-login">Zaloguj</button>
          <div class="separator">lub</div>
          <button type="button" class="btn btn-social btn-google" @click="loginWithGoogle">Zaloguj przez Google</button>
          <button type="button" class="btn btn-social btn-facebook" @click="loginWithFacebook">Zaloguj przez Facebook</button>
        </form>
      </div>
    </div>

    <!-- Register Modal -->
    <div v-if="showRegisterModal" class="modal-overlay" @click.self="closeRegisterModal">
      <div class="modal-content">
        <h3>Zarejestruj</h3>
        <form @submit.prevent="register">
          <div class="form-group">
            <label for="registerEmailOrPhone">E-mail lub numer telefonu</label>
            <input type="text" id="registerEmailOrPhone" v-model="registerEmailOrPhone" class="form-control" required>
          </div>
          <div class="form-group">
            <label for="registerPassword">Hasło</label>
            <input type="password" id="registerPassword" v-model="registerPassword" class="form-control" required>
          </div>
          <div class="form-group user-type-switch">
            <label for="userType">Jestem:</label>
            <div class="switch-container">
              <span :class="{ 'switch-option': true, 'active': userType === 'parent' }" @click="userType = 'parent'">Szukam niani</span>
              <span :class="{ 'switch-option': true, 'active': userType === 'nanny' }" @click="userType = 'nanny'">Jestem nianią</span>
            </div>
          </div>
          <div class="form-group checkbox-group">
            <div>
              <input type="checkbox" id="terms" v-model="acceptTerms" required>
              <label for="terms" class="checkbox-label">Akceptuję <router-link to="/terms">regulamin serwisu</router-link></label>
            </div>
            <div>
              <input type="checkbox" id="privacy" v-model="acceptPrivacyPolicy" required>
              <label for="privacy" class="checkbox-label">Akceptuję <router-link to="/privacy-policy">politykę prywatności</router-link></label>
            </div>
          </div>
          <button type="submit" class="btn btn-primary btn-register">Zarejestruj</button>
          <div class="separator">lub</div>
          <button type="button" class="btn btn-social btn-google" @click="registerWithGoogle">Zarejestruj przez Google</button>
          <button type="button" class="btn btn-social btn-facebook" @click="registerWithFacebook">Zarejestruj przez Facebook</button>
        </form>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'AppNavbar',
  data() {
    return {
      isLoggedIn: false, // Replace this with actual login check logic
      showLoginModal: false,
      showRegisterModal: false,
      emailOrPhone: '',
      password: '',
      registerEmailOrPhone: '',
      registerPassword: '',
      userType: 'parent',
      acceptTerms: false,
      acceptPrivacyPolicy: false,
      messages: [
        "Z miłości do dzieci...",
        "Twoje dziecko w najlepszych rękach...",
        "Profesjonalna opieka 24/7...",
        "Zaufaj naszym doświadczonym opiekunom..."
      ],
      currentMessageIndex: 0
    };
  },
  computed: {
    filteredMessages() {
      return [this.messages[this.currentMessageIndex]];
    }
  },
  mounted() {
    this.startCarousel();
  },
  methods: {
    logout() {
      // Logic for logging out
      this.isLoggedIn = false;
      this.$router.push('/');
    },
    startCarousel() {
      setInterval(() => {
        this.currentMessageIndex = (this.currentMessageIndex + 1) % this.messages.length;
      }, 3000); // Zmiana wiadomości co 3 sekundy
    },
    login() {
      // Placeholder for login logic
      console.log('Logowanie: ', this.emailOrPhone, this.password);
      this.isLoggedIn = true;
      this.closeLoginModal();
    },
    loginWithGoogle() {
      // Placeholder for Google login logic
      console.log('Logowanie przez Google');
    },
    loginWithFacebook() {
      // Placeholder for Facebook login logic
      console.log('Logowanie przez Facebook');
    },
    closeLoginModal() {
      this.showLoginModal = false;
    },
    register() {
      // Placeholder for register logic
      console.log('Rejestracja: ', this.registerEmailOrPhone, this.registerPassword, this.userType, this.acceptTerms, this.acceptPrivacyPolicy);
      this.isLoggedIn = true;
      if (this.userType === 'parent') {
        this.$router.push({ name: 'nannies' });
      } else if (this.userType === 'nanny') {
        this.$router.push({ name: 'nanny-user' });
      }
      this.closeRegisterModal();
    },
    registerWithGoogle() {
      // Placeholder for Google register logic
      console.log('Rejestracja przez Google');
    },
    registerWithFacebook() {
      // Placeholder for Facebook register logic
      console.log('Rejestracja przez Facebook');
    },
    closeRegisterModal() {
      this.showRegisterModal = false;
    }
  }
};
</script>

<style scoped>
.navbar {
  margin-bottom: 5px;
  height: 80px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color:#F7E6FF;
}

.logo-link {
  margin-right: auto;
}

.logo {
  height: 80px;
}

.navbar-carousel {
  flex-grow: 1;
  text-align: center;
  overflow: hidden;
  margin-left: 300px;
}

.carousel-wrapper {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(231, 156, 253, 0.4);
  border-radius: 10px;
  padding: 10px;
}

.carousel-message {
  position: absolute;
  transition: opacity 0.5s ease;
  font-size: 1.5em;
  font-weight: bold;
  color: #fff;
  font-family: 'Comic Sans MS', cursive, sans-serif;
}

.navbar-nav {
  margin-left: auto;
}

.nav-button {
  margin: 0 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  transition: transform 0.3s ease;
}

.btn-primary,
.btn-secondary {
  background-color: #e79cfd;
  border-color: #e386ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-primary:hover,
.btn-secondary:hover {
  transform: scale(1.05);
  text-decoration: none;
  background-color: #e79cfd;
  border-color: #e386ff;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.form-group {
  margin-bottom: 15px;
}

.btn-login,
.btn-register {
  width: 100%;
  margin: 15px 0;
  text-align: center;
}

.separator {
  text-align: center;
  margin: 15px 0;
  font-weight: bold;
  color: #888;
}

.btn-social {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-social:hover {
  transform: scale(1.05);
  text-decoration: none;
  background-color: #f3d8fc;
  border-color: #e386ff;
}

.switch-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.switch-option {
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  background-color: #f3d8fc;
  transition: background-color 0.3s ease;
}

.switch-option.active {
  background-color: #e79cfd;
  color: #fff;
  font-weight: bold;
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkbox-label {
  font-weight: bold;
  color: #555;
  margin-left: 5px;
}
</style>