import AppNavbar from './components/AppNavbar.vue';

<template>
  <div id="app">
    <Navbar />
    <router-view />
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue';

export default {
  name: 'App',
  components: {
    Navbar
  }
};
</script>

<style>
body {
  font-family: Arial, sans-serif;
}
</style>
