<template>
  <section class="availability-section modern-card">
    <h2>Dostępność</h2>
    <button @click="toggleModal" class="btn btn-secondary btn-add">Dodaj dostępność</button>
    <AvailabilityModal v-if="showModal" @add-availability="addAvailability" @close-modal="toggleModal" />
    <div class="availability-list" v-if="localAvailability.length">
      <h3>Twoje dostępności:</h3>
      <ul>
        <li v-for="(availability, index) in localAvailability" :key="index">
          {{ availability.dateRange || availability.singleDay }}: {{ availability.startTime }} - {{ availability.endTime }}
          <button @click="removeAvailability(index)" class="btn btn-danger btn-remove">Usuń</button>
        </li>
      </ul>
    </div>
    <div v-else>
      <p>Nie masz jeszcze żadnych dostępności.</p>
    </div>
  </section>
</template>

<script>
import AvailabilityModal from './AvailabilityModal.vue';

export default {
  name: 'AvailabilitySection',
  props: {
    availability: {
      type: Array,
      default: () => []
    }
  },
  components: {
    AvailabilityModal
  },
  data() {
    return {
      showModal: false,
      localAvailability: [...this.availability]
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    addAvailability(availability) {
      this.localAvailability.push(availability);
      this.$emit('add-availability', availability);
      this.toggleModal();
    },
    removeAvailability(index) {
      this.localAvailability.splice(index, 1);
      this.$emit('remove-availability', index);
    }
  },
  watch: {
    availability: {
      immediate: true,
      handler(newAvailability) {
        this.localAvailability = [...newAvailability];
      }
    }
  }
};
</script>

<style scoped>
.availability-section.modern-card {
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.modern-select.modern-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 100%;
  transition: border-color 0.3s;
}

.modern-select.modern-input:focus {
  border-color: #007bff;
}
.availability {
  margin-bottom: 30px;
}

.btn-add {
  background-color: #2196f3;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.btn-add:hover {
  background-color: #1e88e5;
}

.btn-remove {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

.btn-remove:hover {
  background-color: #e53935;
}

.availability-list {
  margin-top: 20px;
}
</style>
