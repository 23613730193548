<template>
    <div class="profile-header modern-card">
      <input type="file" @change="$emit('picture-change', $event.target.files[0])" class="profile-picture-input">
      <div class="profile-picture-container">
        <img :src="pictureUrl || require('@/assets/default-profile.png')" alt="Profile Picture" class="profile-picture modern-picture">
      </div>
      <div class="profile-info">
        <input type="text" v-model="localName" placeholder="Imię i nazwisko" class="profile-input modern-input" @input="$emit('update:name', localName)">
        <input type="text" v-model="localLocation" placeholder="Lokalizacja" class="profile-input modern-input" @input="$emit('update:location', localLocation)">
        <div class="rating" v-if="reviewsCount > 0">
          <span v-for="n in 5" :key="n" class="star" :class="{ 'filled': n <= rating }">★</span>
          <span class="rating-count">({{ reviewsCount }} opinii)</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProfileHeader',
    props: {
      name: String,
      location: String,
    },
    data() {
      return {
        localName: this.name,
        localLocation: this.location
      };
    },
      pictureUrl: String,
      rating: Number,
      reviewsCount: Number
    }
  </script>
  
  <style scoped>
  .profile-header.modern-card {
    padding-bottom: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-picture-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1vh;
  }

  .profile-picture.modern-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .profile-input.modern-input {
    font-size: 1.2em;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    transition: border-color 0.3s;
  }

  .profile-input.modern-input:focus {
    border-color: #007bff;
  }

  .profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #e79cfd;
  }
  
  .profile-picture-input {
    display: none;
  }
  
  .profile-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 2vh;
    align-items: center;
    width: 80%;
  }
  
  .profile-input {
    font-size: 1.2em;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .rating {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .star {
    font-size: 1.5em;
    color: #ffd700;
  }
  
  .rating-count {
    font-size: 0.9em;
    color: #757575;
  }
  </style>