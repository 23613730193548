<template>
  <div class="home">
    <div class="header">
        <div class="header-overlay">
            <h1>Witaj na <img :src="require('@/assets/logo.png')" alt="logo" class="logo"></h1>
            <h4>Znajdź nianię dla swojej pociechy już teraz</h4>
        </div>
    </div>
    <div class="split-buttons">
      <router-link to="/nannies" class="button-section button-left">
        <img :src="require('@/assets/nanny_search.jpg')" alt="Szukam Niani">
        <div class="button-text">Szukam Niani</div>
      </router-link>
      <router-link to="/register" class="button-section button-right">
        <img :src="require('@/assets/become_nanny.jpg')" alt="Jestem nianią">
        <div class="button-text">Jestem Nianią</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage'
};
</script>

<style scoped>
.home {
  text-align: center;
  background-color: #ffe6ff;
  min-height: 100vh;
  position: relative;
}

.header {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.header-overlay {
  position: relative;
  background-color: rgba(78, 78, 78, 0.527);
  padding: 20px;
  display: inline-block;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.logo {
  width: 150px;
  height: auto;
}

h1 {
  font-size: 2.5em;
  color: #fff;
}
h4 {
  color: #fff;
}

.split-buttons {
  display: flex;
  height: 100vh;
  width: 100%;
}

.button-section {
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}

.button-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.button-section:hover img {
  transform: scale(1.1);
}

.button-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 20px;
  font-size: 2em;
  border-radius: 10px;
}
</style>