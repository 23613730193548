<template>
  <div class="nannies">
    <h2 class="title">Dostępne nianie</h2>
    <div class="filters-container" :class="{ 'filters-container-fixed': currentStep === 3, 'filters-container-step-2': currentStep === 2 }">
      <div class="form-container">
        <h2>Podaj szczegóły</h2>
        <div class="form-group">
          <label for="location">Miejscowość:</label>
          <select v-model="selectedLocation" class="form-select">
            <option disabled value="">Wybierz miejscowość</option>
            <option v-for="location in locations" :key="location" :value="location">{{ location }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="gender">Płeć niani:</label>
          <select v-model="selectedGender" class="form-select">
            <option disabled value="">Wybierz płeć</option>
            <option value="female">Kobieta</option>
            <option value="male">Mężczyzna</option>
          </select>
        </div>
        <div class="form-group">
          <label for="age">Przedział wiekowy:</label>
          <select v-model="selectedAgeRange" class="form-select">
            <option disabled value="">Wybierz przedział wiekowy</option>
            <option value="18-25">18-25</option>
            <option value="26-35">26-35</option>
            <option value="36-50">36-50</option>
            <option value="50+">50+</option>
          </select>
        </div>
        <div class="form-group">
          <label for="price">Przedział cenowy:</label>
          <select v-model="selectedPriceRange" class="form-select">
            <option disabled value="">Wybierz przedział cenowy</option>
            <option value="20-30">20-30 zł/h</option>
            <option value="31-50">31-50 zł/h</option>
            <option value="51+">51+ zł/h</option>
          </select>
        </div>
        <div class="form-group">
          <label for="date">Termin:</label>
          <input type="date" v-model="selectedDate" class="form-input">
        </div>
        <button @click="showNannies" class="btn btn-primary">Pokaż dostępne nianie</button>
      </div>
    </div>

    <div class="nanny-list" :class="{ show: showList }" v-if="currentStep >= 3">
      <div class="nanny-card" 
           v-for="nanny in filteredNannies" 
           :key="nanny.id" 
           @click="toggleNannyDetails(nanny)"
           :class="{ 'expanded': expandedNanny === nanny }">
        <img v-if="expandedNanny !== nanny" :src="nanny.image" alt="Zdjęcie niani" class="nanny-photo">
        <div class="nanny-info" v-if="expandedNanny !== nanny">
          <h3>{{ nanny.name }}</h3>
          <p>Wiek: {{ nanny.age }}</p>
          <p>{{ getShortDescription(nanny.description) }}</p>
        </div>
        <div v-if="expandedNanny === nanny" class="nanny-details">
          <button class="close-button" @click.stop="toggleNannyDetails(null)">&times;</button>
          <div class="nanny-details-content">
            <div class="nanny-header">
              <img :src="nanny.image" alt="Zdjęcie niani" class="nanny-photo-expanded">
              <div class="nanny-header-info">
                <h3 class="nanny-name">{{ nanny.name }}</h3>
                <div class="verifications">
                  <div v-for="verification in nanny.verifications" :key="verification" class="verification-icon" :title="verification">
                    <img :src="require(`@/assets/icons/${verification}.png`)" :alt="verification" />
                  </div>
                </div>
              </div>
            </div>
            <div class="nanny-description-container">
              <p class="nanny-description">Opis: {{ nanny.description }}</p>
            </div>
            <div class="nanny-price-container">
              <p class="nanny-price">Widełki finansowe: {{ nanny.price }} zł/h</p>
            </div>
            <div class="qualifications">
              <span v-for="qualification in nanny.qualifications.split(',')" :key="qualification" class="qualification">{{ qualification }}</span>
            </div>
            <button class="btn btn-primary select-nanny-button">Wybieram nianię</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NannyList',
  data() {
    return {
      currentStep: 1,
      showList: false, // Dodana zmienna do kontroli wyświetlania listy niań
      selectedLocation: '',
      selectedGender: '',
      selectedAgeRange: '',
      selectedPriceRange: '',
      selectedDate: '',
      expandedNanny: null,
      locations: ['Warszawa', 'Kraków', 'Wrocław', 'Gdańsk'],
      nannies: [
        { id: 1, name: 'Anna Kowalska', age: 28, gender: 'female', location: 'Warszawa', price: 30, image: require('@/assets/nanny1.jpg'), description: 'Doświadczona niania z pasją do pracy z dziećmi', qualifications: 'Certyfikat pierwszej pomocy, Kurs opiekuna dzieci', verifications: ['phone_verification', 'document_verification', 'interview_verification', 'knowledge_test_verification'] },
        { id: 2, name: 'Maria Nowak', age: 32, gender: 'female', location: 'Kraków', price: 35, image: require('@/assets/nanny2.jpg'), description: 'Opiekunka z kilkuletnim doświadczeniem', qualifications: 'Kurs opiekuna dzieci', verifications: ['phone_verification', 'document_verification'] },
        { id: 3, name: 'Janina Wiśniewska', age: 45, gender: 'female', location: 'Wrocław', price: 40, image: require('@/assets/nanny3.jpg'), description: 'Pasjonatka opieki nad dziećmi z doświadczeniem w pracy z niemowlętami', qualifications: 'Certyfikat opiekuna niemowląt', verifications: ['phone_verification', 'interview_verification'] },
        { id: 4, name: 'Anna Kowalska', age: 28, gender: 'female', location: 'Warszawa', price: 30, image: require('@/assets/nanny1.jpg'), description: 'Doświadczona niania z pasją do pracy z dziećmi', qualifications: 'Certyfikat pierwszej pomocy, Kurs opiekuna dzieci', verifications: ['phone_verification', 'document_verification', 'interview_verification', 'knowledge_test_verification'] },
        { id: 5, name: 'Maria Nowak', age: 32, gender: 'female', location: 'Kraków', price: 35, image: require('@/assets/nanny2.jpg'), description: 'Opiekunka z kilkuletnim doświadczeniem', qualifications: 'Kurs opiekuna dzieci', verifications: ['phone_verification', 'document_verification'] },
        { id: 6, name: 'Janina Wiśniewska', age: 45, gender: 'female', location: 'Wrocław', price: 40, image: require('@/assets/nanny3.jpg'), description: 'Pasjonatka opieki nad dziećmi z doświadczeniem w pracy z niemowlętami', qualifications: 'Certyfikat opiekuna niemowląt', verifications: ['phone_verification', 'interview_verification'] },
      ],
    };
  },
  computed: {
    filteredNannies() {
      return this.nannies.filter(nanny => {
        return (
          (!this.selectedLocation || nanny.location === this.selectedLocation) &&
          (!this.selectedGender || nanny.gender === this.selectedGender) &&
          (!this.selectedAgeRange || this.isAgeInRange(nanny.age, this.selectedAgeRange)) &&
          (!this.selectedPriceRange || this.isPriceInRange(nanny.price, this.selectedPriceRange))
        );
      });
    }
  },
  methods: {
    showNannies() {
      this.currentStep = 2; // Pierwsza część animacji
      setTimeout(() => {
        this.currentStep = 3; // Druga część animacji, przesunięcie do lewej strony
        this.showList = true; // Wyświetl listę niań
      }, 500); // Czas animacji w milisekundach
    },
    isAgeInRange(age, range) {
      const [min, max] = range.split('-').map(Number);
      return age >= min && (!max || age <= max);
    },
    isPriceInRange(price, range) {
      const [min, max] = range.split('-').map(Number);
      return price >= min && (!max || price <= max);
    },
    getShortDescription(description) {
      return description.length > 50 ? description.substring(0, 50) + '...' : description;
    },
    toggleNannyDetails(nanny) {
      this.expandedNanny = this.expandedNanny === nanny ? null : nanny;
    }
  }
};
</script>

<style scoped>
.nannies {
  text-align: center;
  padding: 20px;
  position: relative;
}

.title {
  position: sticky;
  top: 0;
  background-color: #ffe6ff;
  padding: 10px;
  z-index: 10;
}

.filters-container {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform: translateX(0);
  opacity: 1;
}

.filters-container-step-2 {
  opacity: 0.8;
}

.filters-container-fixed {
  position: fixed;
  left: 20px;
  width: 300px;
  z-index: 10;
  background-color: #ffe6ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform: translateX(0%);
  opacity: 1;
}

.form-container {
  background-color: rgba(255, 230, 255, 0.7);
  padding: 30px;
  border-radius: 15px;
  max-width: 500px;
  margin: 0 auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-select,
.form-input {
  width: 100%;
  padding: 10px;
  font-size: 1.1em;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 5px;
}

.btn {
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
}

.btn-primary {
  background-color: #ff66b2;
  color: #fff;
  border: none;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #ff3385;
}

.select-nanny-button {
  margin-top: 20px;
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.nanny-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 40px;
  margin-left: 350px;
  opacity: 0; /* Domyślnie ukryta lista */
  transition: opacity 0.5s ease-in-out;
}

.nanny-list.show {
  opacity: 1; /* Pokazanie listy z animacją */
}

.nanny-card {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  padding: 20px;
  text-align: left;
  transition: transform 0.3s ease;
  cursor: pointer;
  position: relative;
}

.nanny-card.expanded {
  transform: scale(1.05);
  width: 50%;
  max-width: 700px;
  height: auto;
  padding: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.05);
  z-index: 100;
  background-color: #ffe6ff;
  overflow-y: auto;
  max-height: 90vh;
}

.nanny-photo {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.nanny-photo-expanded {
  width: 150px;
  height: auto;
  border-radius: 10px;
  margin: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.nanny-info {
  margin-top: 15px;
}

.nanny-details-content {
  position: relative;
  padding-top: 50px;
}

.nanny-header {
  display: flex;
  align-items: center;
}

.nanny-header-info {
  flex-grow: 1;
  margin-left: 230px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nanny-description-container{
  background-color: rgba(255, 230, 255, 0.7);
  border-radius: 10px;
  margin-top: 35px;
}

.nanny-price-container {
  background-color: rgba(255, 230, 255, 0.7);
  border-radius: 10px;
  margin-top: 15px;
}

.nanny-full-info {
  margin-top: 20px;
  text-align: center;
}

.qualifications {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.qualification {
  background-color: rgba(255, 102, 178, 0.2);
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 0.9em;
  color: #ff66b2;
}

.verifications {
  display: flex;
  gap: 10px;
}

.verification-icon {
  width: 30px;
  height: 30px;
}

.verification-icon img {
  width: 100%;
  height: 100%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: #ff66b2;
}
</style>