<template>
    <section class="about-section modern-card">
      <h2>O mnie</h2>
      <textarea :value="about" @input="$emit('update:about', $event.target.value)" placeholder="Opisz siebie..." class="profile-textarea"></textarea>
    </section>
  </template>
  
  <script>
  export default {
    name: 'AboutSection',
    props: {
      about: String
    },
    emits: ['update:about']
  };
  </script>
  
  <style scoped>
.about-section.modern-card {
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-text.modern-text {
  font-family: 'Roboto', sans-serif;
  color: #333;
  line-height: 1.6;
}
  .profile-textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
</style>