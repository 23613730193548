import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.min.css';

const app = createApp(App);

// Możesz ustawić opcję `productionTip` w Vue 3 za pomocą instancji aplikacji
app.config.productionTip = false;  // W Vue 3 to jest opcja domyślna i można ją pominąć

// Użyj routera
app.use(router);

// Montowanie aplikacji do elementu DOM o identyfikatorze 'app'
app.mount('#app');
