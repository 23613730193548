<template>
  <div>
    <h2>Reservation History</h2>
    <p>History of reservations will appear here...</p>
  </div>
</template>

<script>
export default {
  name: 'ReservationHistory'
};
</script>

<style scoped>
/* Możesz dodać stylizację tutaj */
</style>
