<template>
  <section class="certificates-section modern-card">
    <h2>Certyfikaty</h2>
    <ul>
      <li v-for="(certificate, index) in certificates" :key="certificate.id">
        <input type="file" @change="$emit('certificate-change', index, $event.target.files[0])" class="certificate-input">
        <img :src="certificate.imageUrl || require('@/assets/default-certificate.png')" alt="{{ certificate.name }}" class="certificate-image">
        <input type="text" v-model="certificate.name" placeholder="Nazwa certyfikatu" class="certificate-input">
        <button @click="$emit('remove-certificate', index)" class="btn btn-danger btn-remove">Usuń</button>
      </li>
    </ul>
    <button @click="$emit('add-certificate')" class="btn btn-secondary btn-add">Dodaj certyfikat</button>
  </section>
</template>

<script>
export default {
  name: 'CertificatesSection',
  props: ['certificates']
};
</script>

<style scoped>
.certificates-section.modern-card {
  background: #fafafa;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.btn-modern.btn-secondary {
  background-color: #6c757d;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btn-modern.btn-secondary:hover {
  background-color: #5a6268;
}
.certificates {
  margin-bottom: 30px;
}

.certificate-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.certificate-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

.btn-remove {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.btn-remove:hover {
  background-color: #e53935;
}
</style>