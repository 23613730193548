<template>
  <section class="services modern-card">
    <h2>Usługi</h2>
    
    <ul class="services-list">
      <li v-for="(service, index) in services" :key="service.id" class="service-item">
        <span class="service-name">{{ service.name }}</span>
        <div class="service-input-wrapper">
          <input type="text" v-model="service.price" placeholder="Podaj kwotę na godzinę.." @focus="service.price === '' ? service.price = '' : null" class="service-input modern-input" pattern="[0-9]*" inputmode="numeric">
          <span class="service-input-unit">PLN/h</span>
        </div>
        <button @click="removeService(index)" class="btn btn-modern btn-remove btn-grey">Usuń</button>
      </li>
    </ul>
    <button @click="openModal" class="btn btn-modern btn-add">Dodaj usługę</button>

    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <button @click="closeModal" class="modal-close-button">&times;</button>
        <h3>Wybierz usługi</h3>
        <div class="modal-columns">
          <div class="modal-column">
            <h4>Opieka</h4>
            <button v-for="service in careServices" :key="service" @click="toggleService(service)" :class="{ 'selected': selectedServices.includes(service) }" class="modal-button">{{ service }}</button>
          </div>
          <div class="modal-column">
            <h4>Pomoc domowa</h4>
            <button v-for="service in homeHelpServices" :key="service" @click="toggleService(service)" :class="{ 'selected': selectedServices.includes(service) }" class="modal-button">{{ service }}</button>
          </div>
          <div class="modal-column">
            <h4>Nauka</h4>
            <button v-for="service in learningServices" :key="service" @click="toggleService(service)" :class="{ 'selected': selectedServices.includes(service) }" class="modal-button">{{ service }}</button>
          </div>
        </div>
        
        <div class="modal-buttons">
          <button @click="saveServices" class="btn btn-modern btn-save">Zapisz</button>
          
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ServicesSection',
  props: {
    services: {
      type: Array,
      required: true
    }
  },
  emits: ['add-service', 'remove-service'],
  data() {
    return {
      showModal: false,
      selectedServices: [],
      careServices: ['Opieka nad dziećmi', 'Opieka nad osobami starszymi'],
      homeHelpServices: ['Sprzątanie', 'Pranie', 'Gotowanie'],
      learningServices: ['Korepetycje z matematyki', 'Lekcje języka angielskiego']
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    toggleService(service) {
      if (this.selectedServices.includes(service)) {
        this.selectedServices = this.selectedServices.filter(s => s !== service);
      } else if (this.selectedServices.length < 10) {
        this.selectedServices.push(service);
      }
    },
    saveServices() {
      this.selectedServices.forEach(service => {
        if (!this.services.some(s => s.name === service)) {
          this.$emit('add-service', { id: Date.now() + Math.random(), name: service, price: '' });
        }
      });
      this.selectedServices = [];
      this.closeModal();
    },
    removeService(index) {
      this.$emit('remove-service', index);
    }
  }
};
</script>

<style scoped>
.services.modern-card {
  background: #f9f9f9;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.services-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.service-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
}

.service-name {
  flex: 1;
  font-size: 1.1em;
  margin-right: 15px;
}

.service-input-wrapper {
  display: flex;
  align-items: center;
}

.service-input.modern-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-right: 5px;
  transition: border-color 0.3s;
  flex-basis: 100px;
  -moz-appearance: textfield;
}

.service-input::-webkit-outer-spin-button,
.service-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.service-input-unit {
  font-size: 1em;
  color: #333;
}

.btn-modern.btn-remove {
  background-color: #808080;
  color: #fff;
  border: none;
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.btn-modern.btn-add {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 15px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
  width: 100%;
  max-width: 200px;
  align-self: center;
  margin-top: 20px;
}

.btn-modern.btn-add:hover {
  background-color: #0056b3;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fafafa;
  padding: 30px;
  border-radius: 10px;
  width: 100%;
  max-width: 700px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-columns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 3vh;
  width: 100%;
  gap: 20px;
}

.modal-column {
  flex: 1;
  margin: 0 10px;
}

.modal-button {
  display: flex;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.modal-button.selected {
  background-color: #007bff;
  color: #fff;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
}

.btn-modern.btn-save, .btn-modern.btn-close {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s;
  margin-left: 10px;
  min-width: 100px;
}

.btn-modern.btn-save:hover, .btn-modern.btn-close:hover {
  background-color: #0056b3;
}
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #333;
  transition: color 0.3s;
}

.modal-close-button:hover {
  color: #007bff;
}
</style>