<template>
  <div>
    <h2>Nanny Profile</h2>
    <p>Profile details will go here...</p>
  </div>
</template>

<script>
export default {
  name: 'NannyProfile'
};
</script>

<style scoped>
/* Dodaj tutaj stylizację, jeśli potrzebujesz */
</style>
