import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './views/HomePage.vue';
import UserLogin from './views/Login.vue';
import UserRegister from './views/Register.vue';
import NannyList from './views/Nannies.vue';
import NannyProfile from './components/NannyProfile.vue';
import ReservationHistory from './views/ReservationHistory.vue';
import NannyUser from './components/NannyUser/NannyUser.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/login',
    name: 'login',
    component: UserLogin
  },
  {
    path: '/register',
    name: 'register',
    component: UserRegister
  },
  {
    path: '/nannies',
    name: 'nannies',
    component: NannyList
  },
  {
    path: '/nanny/:id',
    name: 'nanny-profile',
    component: NannyProfile
  },
  {
    path: '/reservations',
    name: 'reservation-history',
    component: ReservationHistory
  },
  {
    path: '/nannyuser',
    name: 'nanny-user',
    component: NannyUser
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
