<template>
  <div class="nanny-user-profile">
    <div v-if="currentSection === 'ProfileHeader'" class="section-content">
      <ProfileHeader v-model:name="nannyProfile.name" v-model:location="nannyProfile.location" :picture-url="nannyProfile.pictureUrl" @picture-change="onPictureChange" />
      <div class="section-buttons">
        <button class="btn btn-secondary" disabled>Wróć</button>
        <button class="btn btn-next" @click="nextSection">Dalej</button>
      </div>
    </div>
    <div v-if="currentSection === 'AboutSection'" class="section-content">
      <AboutSection v-model:about="nannyProfile.about" />
      <div class="section-buttons">
        <button class="btn btn-secondary" @click="previousSection">Wróć</button>
        <button class="btn btn-next" @click="nextSection">Dalej</button>
      </div>
    </div>
    <div v-if="currentSection === 'ServicesSection'" class="section-content">
      <ServicesSection :services="nannyProfile.services" @add-service="addService" @remove-service="removeService" />
      <div class="section-buttons">
        <button class="btn btn-secondary" @click="previousSection">Wróć</button>
        <button class="btn btn-next" @click="nextSection">Dalej</button>
      </div>
    </div>
    <div v-if="currentSection === 'CertificatesSection'" class="section-content">
      <CertificatesSection :certificates="nannyProfile.certificates" @add-certificate="addCertificate" @remove-certificate="removeCertificate" @certificate-change="onCertificateChange" />
      <div class="section-buttons">
        <button class="btn btn-secondary" @click="previousSection">Wróć</button>
        <button class="btn btn-next" @click="nextSection">Dalej</button>
      </div>
    </div>
    <div v-if="currentSection === 'AvailabilitySection'" class="section-content">
      <AvailabilitySection :availability="nannyProfile.availability" @add-availability="addAvailability" />
      <div class="section-buttons">
        <button class="btn btn-secondary" @click="previousSection">Wróć</button>
        <button class="btn btn-next" @click="saveProfile">Zapisz</button>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileHeader from './ProfileHeader.vue';
import AboutSection from './AboutSection.vue';
import ServicesSection from './ServicesSection.vue';
import CertificatesSection from './CertificatesSection.vue';
import AvailabilitySection from './AvailabilitySection.vue';

export default {
  name: 'NannyUserProfile',
  components: {
    ProfileHeader,
    AboutSection,
    ServicesSection,
    CertificatesSection,
    AvailabilitySection
  },
  data() {
    return {
      currentSection: 'ProfileHeader',
      nannyProfile: {
        pictureUrl: '',
        name: '',
        location: '',
        rating: 0,
        reviewsCount: 0,
        about: '',
        services: [
          { id: 1, name: 'Opieka nad dziećmi', price: '' }
        ],
        certificates: [
          { id: 1, name: '', imageUrl: '' }
        ],
        availability: []
      }
    };
  },
  methods: {
    onPictureChange(file) {
      if (file) {
        this.nannyProfile.pictureUrl = URL.createObjectURL(file);
      }
    },
    addService(service) {
      const existingServiceIndex = this.nannyProfile.services.findIndex(s => s.name === service.name);
      if (existingServiceIndex === -1) {
        this.nannyProfile.services.push(service);
      }
    },
    removeService(index) {
      this.nannyProfile.services.splice(index, 1);
    },
    addCertificate() {
      this.nannyProfile.certificates.push({ id: Date.now(), name: '', imageUrl: '' });
    },
    removeCertificate(index) {
      this.nannyProfile.certificates.splice(index, 1);
    },
    onCertificateChange(index, file) {
      if (file) {
        this.nannyProfile.certificates[index].imageUrl = URL.createObjectURL(file);
      }
    },
    addAvailability(availability) {
      this.nannyProfile.availability.push(availability);
    },
    nextSection() {
      const sections = ['ProfileHeader', 'AboutSection', 'ServicesSection', 'CertificatesSection', 'AvailabilitySection'];
      const currentIndex = sections.indexOf(this.currentSection);
      if (currentIndex < sections.length - 1) {
        this.currentSection = sections[currentIndex + 1];
      }
    },
    previousSection() {
      const sections = ['ProfileHeader', 'AboutSection', 'ServicesSection', 'CertificatesSection', 'AvailabilitySection'];
      const currentIndex = sections.indexOf(this.currentSection);
      if (currentIndex > 0) {
        this.currentSection = sections[currentIndex - 1];
      }
    },
    saveProfile() {
      // Logic to save the profile data to the database
      console.log('Saving profile:', this.nannyProfile);
    }
  }
};
</script>

<style scoped>
.nanny-user-profile {
  max-width: 800px;
  margin: 0 auto;
  margin-top: 10vh;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.section-content {
  width: 100%;
}

.section-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.btn-primary,
.btn-secondary,
.btn-danger,
.btn-next {
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #4caf50;
  border: none;
  padding: 10px 20px;
  color: #fff;
}

.btn-primary:hover {
  background-color: #45a047;
}

.btn-secondary {
  background-color: #2196f3;
  border: none;
  padding: 10px 20px;
  color: #fff;
}

.btn-secondary:hover {
  background-color: #1e88e5;
}

.btn-danger {
  background-color: #f44336;
  border: none;
  padding: 10px 20px;
  color: #fff;
}

.btn-danger:hover {
  background-color: #e53935;
}

.btn-next {
  background-color: #f8b8e0;
  border: none;
  padding: 10px 20px;
  color: #fff;
}

.btn-next:hover {
  background-color: #f497c0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.availability-list {
  margin-top: 20px;
}

.modern-btn {
  margin-top: 20px;
  padding: 10px 20px;
  width: 100%;
}

.modern-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}
</style>