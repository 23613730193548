<template>
  <div class="modal">
    <div class="modal-content">
      <h3>Dodaj dostępność:</h3>
      <div class="availability-buttons">
        <button @click="setAddRangeMode" class="btn btn-modern btn-secondary">Dodaj zakres dat</button>
        <button @click="setAddSingleDayMode" class="btn btn-modern btn-secondary">Dodaj pojedynczy dzień</button>
      </div>
      <div v-if="addRangeMode" class="add-range">
        <h4>Dodaj zakres dat:</h4>
        <div class="date-buttons">
          <button @click="selectStartDate" :disabled="!!startDate" class="btn btn-modern btn-secondary">Data od</button>
          <button @click="selectEndDate" :disabled="!startDate || !!endDate" class="btn btn-modern btn-secondary">Data do</button>
        </div>
        <vue-datepicker 
          v-if="showStartDatePicker" 
          v-model="startDate" 
          :is24hr="true" 
          format="dd-MM-yyyy" 
          :show-time="false" 
          @change="updateStartDate" 
          class="calendar-modern modern-calendar"
          :translations="plTranslations"
          :hide-time="true"
          :buttons="datePickerButtons" />
        <vue-datepicker 
          v-if="showEndDatePicker" 
          v-model="endDate" 
          :is24hr="true" 
          format="dd-MM-yyyy" 
          :show-time="false" 
          @change="updateEndDate" 
          class="calendar-modern modern-calendar"
          :translations="plTranslations"
          :hide-time="true"
          :buttons="datePickerButtons" />
      </div>
      <div v-if="addSingleDayMode" class="add-single-day">
        <h4>Dodaj pojedynczy dzień:</h4>
        <vue-datepicker 
          v-model="singleDay" 
          :is24hr="true" 
          format="dd-MM-yyyy" 
          :show-time="false" 
          @change="updateSingleDay" 
          class="calendar-modern modern-calendar"
          :translations="plTranslations"
          :hide-time="true"
          :buttons="datePickerButtons" />
      </div>
      <div class="availability-times" v-if="(addRangeMode && startDate && endDate) || addSingleDayMode">
        <label>Godzina od:
          <select v-model="startTime" class="modern-select">
            <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}:00</option>
          </select>
        </label>
        <label>Godzina do:
          <select v-model="endTime" class="modern-select">
            <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}:00</option>
          </select>
        </label>
      </div>
      <button @click="emitAvailability" class="btn btn-primary modern-btn">Dodaj dostępność</button>
      <button @click="$emit('close-modal')" class="btn btn-danger modern-btn">Anuluj</button>
    </div>
  </div>
</template>

<script>
import VueDatepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: 'AvailabilityModal',
  components: {
    VueDatepicker
  },
  data() {
    return {
      addRangeMode: false,
      addSingleDayMode: false,
      startDate: null,
      endDate: null,
      singleDay: null,
      startTime: '',
      endTime: '',
      hours: Array.from({ length: 24 }, (_, i) => i),
      showStartDatePicker: false,
      showEndDatePicker: false,
      plTranslations: {
        weekdays: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
        months: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
        monthsShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
        buttons: {
          cancel: 'Anuluj',
          select: 'Wybierz'
        }
      },
      datePickerButtons: [
        { label: 'Anuluj', className: 'btn btn-danger modern-btn', action: (instance) => instance.close() },
        { label: 'Wybierz', className: 'btn btn-primary modern-btn', action: (instance) => instance.selectDate() }
      ]
    };
  },
  methods: {
    setAddRangeMode() {
      this.addRangeMode = true;
      this.addSingleDayMode = false;
      this.startDate = null;
      this.endDate = null;
      this.showStartDatePicker = false;
      this.showEndDatePicker = false;
    },
    setAddSingleDayMode() {
      this.addRangeMode = false;
      this.addSingleDayMode = true;
      this.singleDay = null;
    },
    selectStartDate() {
      this.showStartDatePicker = true;
      this.showEndDatePicker = false;
    },
    selectEndDate() {
      this.showStartDatePicker = false;
      this.showEndDatePicker = true;
    },
    updateStartDate(date) {
      this.startDate = date;
      this.showStartDatePicker = false;
    },
    updateEndDate(date) {
      this.endDate = date;
      this.showEndDatePicker = false;
    },
    updateSingleDay(date) {
      this.singleDay = date;
    },
    emitAvailability() {
      if (((this.startDate && this.endDate) || this.singleDay) && this.startTime !== '' && this.endTime !== '' && this.startTime < this.endTime) {
        const availabilityData = {
          startTime: `${this.startTime}:00`,
          endTime: `${this.endTime}:00`
        };
        if (this.startDate && this.endDate) {
          availabilityData.dateRange = `${new Date(this.startDate).toLocaleDateString()} - ${new Date(this.endDate).toLocaleDateString()}`;
        } else if (this.singleDay) {
          availabilityData.singleDay = new Date(this.singleDay).toLocaleDateString();
        }
        this.$emit('add-availability', availabilityData);
      } else {
        alert('Proszę wybrać prawidłowe daty oraz godziny (godzina zakończenia musi być późniejsza niż godzina rozpoczęcia).');
      }
    }
  },
  mounted() {
    this.$forceUpdate();
  }
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.availability-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.date-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.availability-times {
  margin-top: 15px;
  display: flex;
  gap: 15px;
}

.btn-primary,
.btn-secondary,
.btn-danger {
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #4caf50;
  border: none;
  padding: 10px 20px;
  color: #fff;
}

.btn-primary:hover {
  background-color: #45a047;
}

.btn-secondary {
  background-color: #2196f3;
  border: none;
  padding: 10px 20px;
  color: #fff;
}

.btn-secondary:hover {
  background-color: #1e88e5;
}

.btn-danger {
  background-color: #f44336;
  border: none;
  padding: 10px 20px;
  color: #fff;
}

.btn-danger:hover {
  background-color: #e53935;
}

.modern-btn {
  margin-top: 20px;
  padding: 10px 20px;
  width: 100%;
}

.modern-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}
</style>
